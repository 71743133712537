/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import CompareProducts from "../components/Products/CompareProducts"
import SEO from "../components/SEO/seo"
import Layout from "../zzz/layout"
import { LinkWrapper as Link } from "../utils/linkWrapper"
import "react-tabs/style/react-tabs.css"
import Heading from "../zzz/organisms/heading"

const CompareProductsPage = ({ location }) => {
  const data = useStaticQuery(PAGE)
  const seo = data.page.seo

  return (
    <Layout location={location}>
      <SEO
        title="Compare Products"
        location={location}
        post_id={54114}
        seo={seo}
      />
      <Heading location={location} />
      <CompareProducts />
    </Layout>
  )
}

export default CompareProductsPage

const PAGE = graphql`
  {
    page: localWpGraphQlPages(pageId: { eq: 54114 }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
    }
  }
`
